import React, { useEffect, useRef, useState } from "react";
import { useLayout } from "../hooks/LayoutContext";

import Hero from "../components/hero/Hero";
import Header from "../components/header/Header";
import FeaturesComponent from "../components/features/Features";
import Footer from "../components/footer/Footer";
import ContactUs from "../components/contact-us/ContactUs";
import HeaderCollapse from "../components/header/HeaderCollapse";
import ScrollToTopButton from "../components/scroll-to-top/ScrollToTopButton";
import WhatsappButton from "../components/whatsapp-button/WhatsappButton";
import WhyChooseUs from "../components/why-choose-us/WhyChooseUs";
// import MeetTheTeam from "../components/meet-the-team/MeetTheTeam";
import ProductOverview from "../components/product-overview/ProductOverview";
import WhyBizmate from "../components/why-bizmate/WhyBizmate";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import InterestQuestions from "../components/questions/InterestsQuestions";

const Content = () => {
    const { layoutState } = useLayout();
    const [black, setBlack] = useState<boolean>(false);
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
    const refHero = useRef<any>(null);
    const refFeatures = useRef<any>(null);
    const intl = useIntl();

    const handleScroll = () => {
        if (refHero.current) {
            const heroHeight = refHero.current.offsetHeight;
            if (window.scrollY > heroHeight - 30) {
                setBlack(true);
            } else {
                setBlack(false);
            }
        }
    };

    useEffect(() => {
        if (layoutState.dimension.width > 768) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [layoutState.dimension.width]); 

    return (
        <>
            <Helmet>
                <title>{intl.formatMessage({id: 'HELMET_DEFAULT_TITLE'})}</title>
                <meta name="description" content={`${intl.formatMessage({id: 'BIZMATE_SLOGAN_LINE1'})} ${intl.formatMessage({id: 'BIZMATE_SLOGAN_LINE2'})}`} />
            </Helmet>

            <HeaderCollapse isSubPage={false}/>
            <Header isBlack={black} refHero={refHero} isSubPage={false}/>
            <Hero refHome={refHero} />
            {/* <MeetTheTeam /> */}
            <ProductOverview />
            <WhyBizmate />
            <FeaturesComponent refFeatures={refFeatures} />
            <WhyChooseUs />
            <InterestQuestions />
            <ContactUs />
            <WhatsappButton isScrollButtonVisible={isScrollButtonVisible} />
            <ScrollToTopButton setIsScrollButtonVisible={setIsScrollButtonVisible} />
            <Footer />
        </>
    )
};

export default Content;
