import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Skeleton } from "antd";
import TeamIllustrationSrc from "../../assets/images/team-illustration-2.svg";
import "./WhyBizmate.less";

const WhyBizmate = ({ refWhyBizmate }: any) => {
    const intl = useIntl();
    const whyBizmateImageColumnRef = useRef<HTMLDivElement>(null);
    const whyBizmateTextColumnRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const imageObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        imageObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const textObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        textObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentImageRef = whyBizmateImageColumnRef.current;
        const currentTextRef = whyBizmateTextColumnRef.current;

        if (currentImageRef) {
            imageObserver.observe(currentImageRef);
        }

        if (currentTextRef) {
            textObserver.observe(currentTextRef);
        }

        return () => {
            if (currentImageRef) {
                imageObserver.unobserve(currentImageRef);
            }
            if (currentTextRef) {
                textObserver.unobserve(currentTextRef);
            }
        };
    }, []);

    useEffect(() => {
        const backgroundImg = new Image();
        backgroundImg.src = TeamIllustrationSrc;
        backgroundImg.onload = () => {
            setIsLoading(false);
        };
    }, []);

    return (
        <div id="WhyBizmate" ref={refWhyBizmate} className="why-bizmate-container">
            <div ref={whyBizmateImageColumnRef} className="why-bizmate-image-column">
               {isLoading 
                    ?   <Skeleton.Image active={true} className="why-bizmate-skeleton" />
                    :   <img className="why-bizmate-image" src={TeamIllustrationSrc} alt=""  />
                }
            </div>
            <div ref={whyBizmateTextColumnRef} className="why-bizmate-text-column">
                <h2 className="why-bizmate-heading">- <span className="why-bizmate-heading-1">{intl.formatMessage({ id: "WHY" })}</span> {intl.formatMessage({ id: "BIZMATE" })} -</h2>
                <p className="why-bizmate-description-1">{intl.formatMessage({ id: "WHY_BIZMATE_PARAGRAPH_1" })}</p>
                {/* <div className="why-bizmate-btn-content">
                    <a href="#WhyChooseUs" className="why-bizmate-btn">{intl.formatMessage({ id: "LEARN_MORE" })}</a>
                </div> */}
            </div>
        </div>
    );
};

export default WhyBizmate;
