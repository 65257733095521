import React, { useState } from "react";
import Header from "../../components/header/Header";
import HeaderCollapse from "../../components/header/HeaderCollapse";
import Footer from "../../components/footer/Footer";
import ScrollToTopButton from "../../components/scroll-to-top/ScrollToTopButton";
import WhatsappButton from "../../components/whatsapp-button/WhatsappButton";
import appLogo from '../../assets/images/logo_collapsed.svg';
import emailLogo from '../../assets/images/email-color.png';
import whatsappLogo from '../../assets/images/whatsapp-color.png';
import { useIntl } from "react-intl";
import { getPricingPlans } from "../../content/PricingContent";
import { salesContactLink, enquiryEmail, registerUrl } from "../../content/HomeContent";
import "./Pricing.less";
import { Helmet } from "react-helmet";
import { HashLink as Link } from 'react-router-hash-link';

const Plans = () => {
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
    const intl = useIntl();
    const pricingPlans = getPricingPlans(intl);

    return (
        <div className="pricing-container">
            <Helmet>
                <title>{intl.formatMessage({id: 'HELMET_PRICING_TITLE'})}</title>
                <meta name="description" content={intl.formatMessage({id: 'PRICING'})} />
            </Helmet>
            <Header isBlack={true} isSubPage={true} />
            <HeaderCollapse isSubPage={true} />
            <div className="pricing-container-content">
                <div className="bizmate-logo">
                    <img alt="bizmate-logo-hero" className="bizmate-logo-hero" src={appLogo} />
                </div>
                <div className="pricing-header-container">
                    <div className="pricing-subheading">{intl.formatMessage({ id: "PRICING" })}</div>
                    <div className="pricing-heading">
                        <span className="pricing-heading-1">{intl.formatMessage({ id: "PRICING_LINE_1" })}</span>
                        <span className="pricing-heading-2">{intl.formatMessage({ id: "PRICING_LINE_2" })}</span>
                    </div>
                    <div className="pricing-description">
                        {intl.formatMessage({ id: "PRICING_DESCRIPTION" })}<br />
                        {intl.formatMessage({ id: "PRICING_DESCRIPTION_2_1" })} 
                        <Link to="/#Features">{intl.formatMessage({ id: "PRICING_DESCRIPTION_ANCHOR"})}</Link> 
                        {intl.formatMessage({ id: "PRICING_DESCRIPTION_2_2"})}
                    </div>
                </div>
                <div className="pricing-plans-container">
                    {pricingPlans.map((plan, index) => (
                        <div key={index} className={`plan ${plan.featured ? "featured" : ""}`}>
                            {!plan.featured && (
                                <div className={`plan-highlight ${!plan.featured ? "plan-highligh-head-non-featured" : "plan-highligh-head-featured"}`} />
                            )}
                            <div className="plan-header">
                                <span className="name">{plan.name}</span>
                                <span className="price">{plan.price}</span>
                                <span className="credit">{plan.credit}</span>
                            </div>
                            <div className="plan-features">
                                <span className="feature main-feature">{plan.mainFeature}</span>
                                {plan.features && (
                                    <div className="basic-features">
                                        <h3>{plan.basicFeatureText}</h3>
                                        <ul>
                                            {plan.features.map((feature, idx) => (
                                                <li key={idx} className="feature">
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {plan.advancedFeatures && (
                                    <div className="advanced-features">
                                        <h3>{plan.advanceFeatureText}</h3>
                                        <ul>
                                            {plan.advancedFeatures?.map((feature, idx) => (
                                                <li key={idx} className="feature">
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                <div className={` ${plan.featured ? "template-note-featured" : "template-note"}`}>
                                    <p>{intl.formatMessage({ id: "TEMPLATE_NOTES" })}</p>
                                </div>
                            </div>
                            <div className="plan-action">
                                <a href={registerUrl}>
                                    <button className={`buy-now-button ${!plan.featured ? "non-featured-btn" : "featured-btn"}`}>
                                        {plan.buttonText}
                                    </button>
                                </a>
                            </div>
                        </div>
                    ))}
                    <div className="plan">
                        <div className="plan-highlight plan-highligh-head-non-featured" />
                        <div className="plan-header">
                            <span className="name">&nbsp;</span>
                            <span className="price">{intl.formatMessage({ id: "ENTERPRISE" })}</span>
                            <span className="credit">&nbsp;</span>
                        </div>
                        <div className="plan-features">
                            <span className="feature main-feature">{intl.formatMessage({ id: "ENTERPRISE_PLAN_DESC" })}</span>
                        </div>
                        <div className="plan-action-enterprise">
                            <a className="buy-now-button half-button" href={`mailto:${enquiryEmail}`} target="_blank" rel="noreferrer">
                                <img src={emailLogo} alt="email-logo"/>
                            </a>
                            <a className="buy-now-button half-button" href={salesContactLink} target="_blank" rel="noreferrer">
                                <img src={whatsappLogo} alt="whatsapp-logo"/>
                            </a>
                        </div>
                    </div>
                    <svg className="decorator-blob" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
                        <path
                            fill="rgba(56, 178, 172, 1)"
                            d="M276.6,546.1C177.1,567.7,54.8,509.2,26.6,406.4C-0.9,305.8,90.7,207.7,173.4,119.3
                            C254.7,33.1,353.2-48.6,451.1,2.8C552.8,56.9,646.5,166.7,644.9,276.3C643.4,383.2,572.1,471.4,487.2,524.2
                            C411.7,570.8,340.2,526.7,276.6,546.1z"
                        />
                    </svg>
                </div>
            </div>
            <WhatsappButton isScrollButtonVisible={isScrollButtonVisible} />
            <ScrollToTopButton setIsScrollButtonVisible={setIsScrollButtonVisible} />
            <Footer isSubPage={true} />
        </div>
    );
};

export default Plans;
