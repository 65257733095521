import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import './Features.less'; 
import { Carousel, ConfigProvider } from "antd";
import ARActivityListImg from "../../assets/images/features-carousel/AR-Activity-List.webp";
import ARCompanyProfileTableImg from "../../assets/images/features-carousel/AR-Company-Profile-Table.webp";
import ARExportHistoryImg from "../../assets/images/features-carousel/AR-Export-History.webp";
import ARMemberSharesImg from "../../assets/images/features-carousel/AR-Member-Share.webp";
import ARProfileDetailsImg from "../../assets/images/features-carousel/AR-Profile-Details.webp";
import ARResolutionEditorImg from "../../assets/images/features-carousel/AR-Resolution-Editor.webp";
import MBRSListImg from "../../assets/images/features-carousel/MBRS-List.webp";
import MBRSUploadReportImg from "../../assets/images/features-carousel/MBRS-Upload-Report.webp";
import MBRSReportDetailsImg from "../../assets/images/features-carousel/MBRS-Report-Details.webp";
import MBRSGlobalValidationImg from "../../assets/images/features-carousel/MBRS-Global-Validation.webp";
import MBRSReportExportHistoryImg from "../../assets/images/features-carousel/MBRS-Report-Export-History.webp";

const FeaturesComponent = ({ refFeatures }: any) => {
    const intl = useIntl();
    const screenWidth = window.outerWidth;
    const featureHeading = useRef<HTMLDivElement>(null);
    const featuresDescription = useRef<HTMLDivElement>(null);
    const featuresDescription2 = useRef<HTMLDivElement>(null);
    const featuresCarousel = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const imageObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        imageObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const textObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        textObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentHeadingRef = featureHeading.current;
        const currentDescriptionRef = featuresDescription.current;
        const currentDescriptionRef2 = featuresDescription2.current;
        const currentCarouselRef = featuresCarousel.current;
       
        if (currentHeadingRef) {
            textObserver.observe(currentHeadingRef);
        }

        if (currentDescriptionRef) {
            textObserver.observe(currentDescriptionRef);
        }

        if (currentDescriptionRef2) {
            textObserver.observe(currentDescriptionRef2);
        }

        if (currentCarouselRef) {
            imageObserver.observe(currentCarouselRef);
        }
        
        return () => {
            if (currentHeadingRef) {
                textObserver.unobserve(currentHeadingRef);
            }

            if (currentDescriptionRef) {
                textObserver.unobserve(currentDescriptionRef);
            }

            if (currentDescriptionRef2) {
                textObserver.unobserve(currentDescriptionRef2);
            }

            if (currentCarouselRef) {
                imageObserver.unobserve(currentCarouselRef);
            }
        };
    }, []);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Carousel: {
                        arrowOffset: -1000,
                        arrowSize: (screenWidth <= 576) ? 20 : (screenWidth > 576 && screenWidth <= 1024) ? 30 : 40
                    }
                }
            }}
        >
            <div id="Features" ref={refFeatures} className="features-container">
                <h1 className="features-heading" ref={featureHeading}>
                    <span className="features-heading-1">{intl.formatMessage({ id: "FEATURES" })}</span>
                    <br/>
                    <span className="features-heading-2">{intl.formatMessage({ id: "FEATURES_HEADING_LINE1" })}</span>
                </h1>
                
                <div className="feature-description-panel-1" ref={featuresDescription}>
                    <div className="ctms-description">
                        <h2>{intl.formatMessage({ id: "FEATURES_TMS_HEADER" })}</h2>
                        <span className="emphasise-keywords">{intl.formatMessage({ id: "FEATURES_TMS_DESCRIPTION_1_HIGHLIGHT_1" })}</span>
                        <span>{intl.formatMessage({ id: "FEATURES_TMS_DESCRIPTION_1_2" })}</span>
                        <br/><br/>
                        <span>{intl.formatMessage({ id: "FEATURES_TMS_DESCRIPTION_2_1" })}</span>
                        <span className="emphasise-keywords">{intl.formatMessage({ id: "FEATURES_TMS_DESCRIPTION_2_HIGHLIGHT_2" })}</span>
                        <span>{intl.formatMessage({ id: "FEATURES_TMS_DESCRIPTION_2_3" })}</span>
                    </div>
                    <div className="resolution-description">
                        <h2>{intl.formatMessage({ id: "FEATURES_RESOLUTION_HEADER" })}</h2>
                        <span>{intl.formatMessage({ id: "FEATURES_RESOLUTION_DESCRIPTION_1" })}</span>
                        <br/><br/>
                        <span>{intl.formatMessage({ id: "FEATURES_RESOLUTION_DESCRIPTION_2_1" })}</span>
                        <span className="emphasise-keywords">{intl.formatMessage({ id: "FEATURES_RESOLUTION_DESCRIPTION_2_HIGHLIGHT" })}</span>
                        <span>{intl.formatMessage({ id: "FEATURES_RESOLUTION_DESCRIPTION_2_2" })}</span>
                        <br/><br/>                        
                        <span>{intl.formatMessage({ id: "FEATURES_RESOLUTION_DESCRIPTION_3_1" })}</span>
                        <span className="emphasise-keywords">{intl.formatMessage({ id: "FEATURES_RESOLUTION_DESCRIPTION_3_HIGHLIGHT" })}</span>
                        <span>{intl.formatMessage({ id: "FEATURES_RESOLUTION_DESCRIPTION_3_2" })}</span>
                    </div>
                </div>

                <div className="feature-description-panel-2" ref={featuresDescription2}>
                    <div className="annual-return-description">
                        <h2>{intl.formatMessage({id: 'FEATURES_ANNUAL_RETURN_HEADER'})}</h2>
                        <span>{intl.formatMessage({id: 'FEATURES_ANNUAL_RETURN_DESCRIPTION_1'})}</span>
                        <span className="emphasise-keywords">{intl.formatMessage({id: 'FEATURES_ANNUAL_RETURN_DESCRIPTION_HIGHLIGHT_1'})}</span>
                        <span>{intl.formatMessage({id: 'FEATURES_ANNUAL_RETURN_DESCRIPTION_2'})}</span>
                        <span className="emphasise-keywords">{intl.formatMessage({id: 'FEATURES_ANNUAL_RETURN_DESCRIPTION_HIGHLIGHT_2'})}</span>
                        <br/><br/>
                        <span>{intl.formatMessage({id: 'FEATURES_ANNUAL_RETURN_DESCRIPTION_3'})}</span>
                    </div>
                    <div className="mbrs-report-description">
                        <h2>{intl.formatMessage({id: 'FEATURES_MBRS_REPORT_HEADER'})} <span>{intl.formatMessage({id: 'COMING_SOON'})}</span></h2>
                        <span>{intl.formatMessage({id: 'FEATURES_MBRS_REPORT_DESCRIPTION_1_1'})}</span>
                        <span className="emphasise-keywords">{intl.formatMessage({id: 'FEATURES_MBRS_REPORT_DESCRIPTION_1_HIGHLIGHT'})}</span>
                        <span>{intl.formatMessage({id: 'FEATURES_MBRS_REPORT_DESCRIPTION_1_2'})}</span>
                        <br/><br/>
                        <span>{intl.formatMessage({id: 'FEATURES_MBRS_REPORT_DESCRIPTION_2_1'})}</span>
                        <span className="emphasise-keywords">{intl.formatMessage({id: 'FEATURES_MBRS_REPORT_DESCRIPTION_2_HIGHLIGHT_1'})}</span>
                        <span>{intl.formatMessage({id: 'FEATURES_MBRS_REPORT_DESCRIPTION_2_2'})}</span>
                        <span className="emphasise-keywords">{intl.formatMessage({id: 'FEATURES_MBRS_REPORT_DESCRIPTION_2_HIGHLIGHT_2'})}</span>
                        <span>{intl.formatMessage({id: 'FEATURES_MBRS_REPORT_DESCRIPTION_2_3'})}</span>

                    </div>
                </div>
                <div className="carousel-container" ref={featuresCarousel}>
                    <Carousel arrows infinite={true} className="carousel-content">
                        {/* AR Images */}
                        <div>
                            <img src={ARActivityListImg} className="carousel-content" alt="AR Activity List" />
                        </div>
                        <div>
                            <img src={ARCompanyProfileTableImg} className="carousel-content" alt="AR Company Profile Table" />
                        </div>
                        <div>
                            <img src={ARExportHistoryImg} className="carousel-content" alt="AR Export History" />
                        </div>
                        <div>
                            <img src={ARMemberSharesImg} className="carousel-content" alt="AR Member Shares" />
                        </div>
                        <div>
                            <img src={ARProfileDetailsImg} className="carousel-content" alt="AR Profile Details" />
                        </div>
                        <div>
                            <img src={ARResolutionEditorImg} className="carousel-content" alt="AR Resolution Editor" />
                        </div>
                        {/* MBRS Images */}
                        <div>
                            <img src={MBRSListImg} className="carousel-content" alt="AR Activity List" />
                        </div>
                        <div>
                            <img src={MBRSUploadReportImg} className="carousel-content" alt="AR Company Profile Table" />
                        </div>
                        <div>
                            <img src={MBRSReportDetailsImg} className="carousel-content" alt="AR Export History" />
                        </div>
                        <div>
                            <img src={MBRSGlobalValidationImg} className="carousel-content" alt="AR Member Shares" />
                        </div>
                        <div>
                            <img src={MBRSReportExportHistoryImg} className="carousel-content" alt="AR Profile Details" />
                        </div>
                    </Carousel>
                </div>
            </div>
      </ConfigProvider>

    )
};

export default FeaturesComponent;
