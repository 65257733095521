import React, { useState } from "react";
import { Collapse } from "antd";
import { useIntl } from "react-intl";
import { getFaqContent } from "../../content/FaqContent";
import Header from "../../components/header/Header";
import HeaderCollapse from "../../components/header/HeaderCollapse";
import Footer from "../../components/footer/Footer";
import WhatsappButton from "../../components/whatsapp-button/WhatsappButton";
import ScrollToTopButton from "../../components/scroll-to-top/ScrollToTopButton";
import appLogo from '../../assets/images/logo_collapsed.svg';
import { Helmet } from 'react-helmet';
import "./Faq.less";

const Faq = () => {
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
    const intl = useIntl();
    const FaqContent = getFaqContent(intl);

    return (
        <div className="faq-container">
            <Helmet>
                <title>{intl.formatMessage({id: 'HELMET_FAQ_TITLE'})}</title>
                <meta name="description" content={intl.formatMessage({id: 'FAQ_MAIN_HEADER'})} />
            </Helmet>
            
            <Header isBlack={true} isSubPage={true} />
            <HeaderCollapse isSubPage={true} />
            <div className="faq-content-container">
                <div className="bizmate-logo">
                    <img alt="bizmate-logo-hero" className="bizmate-logo-hero" src={appLogo} />
                </div>
                <div className="faq-sub-header">{intl.formatMessage({ id: "FAQ_SUB_HEADER" })}</div>
                <div className="faq-main-header">{intl.formatMessage({ id: "FAQ_MAIN_HEADER" })}</div>
                <div className="faq-qna-container">
                    {FaqContent.map((faq, i) => (
                        <div className="faq-qna-item-container">
                            <Collapse
                                className="faq-qna-item-collapse"
                                expandIconPosition='end'
                                collapsible="header"
                                items={[
                                    {
                                        forceRender: true,
                                        key: i,
                                        label: <><span className="faq-question-number">{i + 1}.</span> {faq.question}</>,
                                        children: <p>{faq.answer}</p>,
                                    },
                                ]}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <WhatsappButton isScrollButtonVisible={isScrollButtonVisible} />
            <ScrollToTopButton setIsScrollButtonVisible={setIsScrollButtonVisible} />
            <Footer isSubPage={true} />
        </div>
    )
};

export default Faq;