import React, { useState } from "react";
import Header from "../../components/header/Header";
import HeaderCollapse from "../../components/header/HeaderCollapse";
import Footer from "../../components/footer/Footer";
import appLogo from '../../assets/images/logo.svg';
import ScrollToTopButton from "../../components/scroll-to-top/ScrollToTopButton";
import WhatsappButton from "../../components/whatsapp-button/WhatsappButton";
import { enquiryEmail } from "../../content/HomeContent";
import "./PrivacyPolicy.less";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

const PrivacyPolicy = () => {
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
    const intl = useIntl();

    return (
        <div className="privacy-policy-container">
            <Helmet>
                <title>{intl.formatMessage({id: 'HELMET_PRIVACY_POLICY_TITLE'})}</title>
                <meta name="description" content={intl.formatMessage({id: 'PRIVACY_POLICY'})} />
            </Helmet>
            <Header isBlack={true} isSubPage={true}/>
            <HeaderCollapse isSubPage={true}/>
            <div className="privacy-policy-container-content">
                <div className="bizmate-logo-pp">
                    <img alt="bizmate-logo" src={appLogo} />
                </div>
                <div className="privacy-policy-header">
                    <h1 className="privacy-policy-heading">Privacy Policy</h1>
                    <p>LAST UPDATE: 15 May 2024</p>
                    <p>E-mail: {enquiryEmail}</p>
                </div>
                <div className="pp-text">
                    <p className="pp-text-header-1">NOTICE UNDER THE PERSONAL DATA PROTECTION ACT 2010</p>
                    <p className="pp-text-body-1">The Personal Data Protection Act 2010 (hereinafter referred to as the “PDPA”), which regolates the processing of personal data in commercial transactions, applies to Bizmate SDN BHD (hereinafter referred to as “Bizmate”, “our”, “us” or “we”). This written notice is issued pursuant to the requirements of the PDPA. For the purpose of this written notice, the terms “personal data” and “processing” shall have the meaning as prescribed in the PDPA.</p>
                    <ol>
                        <li>
                            <p>This written notice serves to inform you how your personal data will be processed and managed by Bizmate.</p>
                        </li>
                        <li>
                            <p>The personal data processed by us may include but not limited to your name, national registration identity card no. (NRIC no.), contact number, address, email address, bank account details and such other information as may be provided by you in the documents as prescribed in paragraph 3 of this notice.</p>
                        </li>
                        <li>
                            <p>We are processing your personal data, including any additional information that you may subsequently provide, for the following purposes (“Purposes”), which may include but not limited to:</p>
                            <ol className="inner-ol">
                                <li>
                                    <p>managing and administering our records in relation to your transaction through the use of our services;</p>
                                </li>
                                <li>
                                    <p>facilitating or enabling any checks that may be conducted on you by us or by any third party from time to time;</p>
                                </li>
                                <li>
                                    <p>complying with the applicable laws including but not limited to user registration process;</p>
                                </li>
                                <li>
                                    <p>assisting any government agencies or bureaus or bodies, including for the purposes of police or regolatory investigations;</p>
                                </li>
                                <li>
                                    <p>facilitating your compliance with any laws or regolations which may be applicable to you;</p>
                                </li>
                                <li>
                                    <p>conducting our internal activities, internal market surveys and trend analysis; and/or</p>
                                </li>
                                <li>
                                    <p>such other purposes as may be related to the foregoing</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <p className="pp-text-body-1">If you fail to provide us with your personal data, or do not consent to our processing of your personal data, we will not be able to process your personal data for any of the above Purposes, and we may also not be able to provide you with our products and/or render you with our services.</p>
                    <ol>
                        <li>
                            <p>Your personal data is and/or will be collected by us from the following sources, including but not limited to:</p>
                            <ol className="inner-ol">
                                <li>
                                    <p>any agreements that you may have signed and executed with us;</p>
                                </li>
                                <li>
                                    <p>any application and/or registration forms submitted by you to us;</p>
                                </li>
                                <li>
                                    <p>any forms submitted by you to us during any contest and/or any promotional or marketing events of our products and/or services;</p>
                                </li>
                                <li>
                                    <p>your use of and/or registration with Bizmate official website;</p>
                                </li>
                                <li>
                                    <p>any emails or correspondences sent by you to us which includes your personal data; and/or</p>
                                </li>
                                <li>
                                    <p>third parties such as credit reporting agencies, law enforcement agencies, fraud prevention agencies and other government entities for any of the aforesaid Purposes.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>In accordance with the PDPA:</p>
                            <ol className="inner-ol">
                                <li>
                                    <p>We may charge a prescribed fee for processing your request for access to and/or correction of your personal data; and</p>
                                </li>
                                <li>
                                    <p>We may refuse to comply with your request for access to and/or correction of your personal data and if we refuse to comply with such request, we will inform you of our refusal and the reason(s) for our refusal.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Your personal data may be disclosed to third parties, which may include but not limited to the following:</p>
                            <ol className="inner-ol">
                                <li>
                                    <p>banks, credit check companies, credit bureaus or credit reporting agencies and fraud prevention agencies;</p>
                                </li>
                                <li>
                                    <p>governmental departments and/or agencies, regolatory and/or statutory bodies;</p>
                                </li>
                                <li>
                                    <p>our third party providers, including but not limited to professional advisors and auditors; and/ or</p>
                                </li>
                                <li>
                                    <p>any such third party as may be requested or authorized by you for any of the Purposes.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Your personal data may be transferred to a place outside Malaysia.</p>
                        </li>
                        <li>
                            <p>You are responsible for ensuring that the personal data you provide to us is accurate, complete and not misleading and that such personal data is kept up to date.</p>
                        </li>
                        <li>
                            <p>We may request your assistance to procure the consent of third parties whose personal data is provided by you to us and you hereby agree to use your best endeavours to do so.</p>
                        </li>
                    </ol>
                </div>
            </div>
            <WhatsappButton isScrollButtonVisible={isScrollButtonVisible} />
            <ScrollToTopButton setIsScrollButtonVisible={setIsScrollButtonVisible} />
            <Footer isSubPage={true}/>
        </div>
    );
};

export default PrivacyPolicy;
