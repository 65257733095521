import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { loginUrl } from "../../content/HomeContent";
import LanguageSwitcher from '../language-switcher/LanguageSwitcher';
import './HeaderCollapse.less';

const FullPageModal = ({ isOpen, onClose, isSubPage }: any) => {
    const intl = useIntl();

    const handleLinkClick = () => {
        onClose();
    };

    return (
        <div className={`header-collapse-modal ${isOpen ? 'active' : ''}`}>
            <nav className="header-collapse-modal-nav">
                <ul>
                    <li><a href={isSubPage ? "/" : "#Home"} onClick={handleLinkClick}>{intl.formatMessage({ id: "HOME" })}</a></li>
                    <li><a href={isSubPage ? "/" : "#ProductOverview"} onClick={handleLinkClick}>{intl.formatMessage({ id: "OVERVIEW" })}</a></li>
                    <li><a href={isSubPage ? "/" : "#Features"} onClick={handleLinkClick}>{intl.formatMessage({ id: "FEATURE" })}</a></li>
                    <li><a href={isSubPage ? "/" : "#Benefits"} onClick={handleLinkClick}>{intl.formatMessage({ id: "BENEFITS" })}</a></li>
                    <li><a href={isSubPage ? "/" : "#ContactUs"} onClick={handleLinkClick}>{intl.formatMessage({ id: "CONTACT_US" })}</a></li>
                    <li><a href="/faq" onClick={handleLinkClick}>{intl.formatMessage({ id: "FAQ" })}</a></li>
                    <li><a href="/pricing" onClick={handleLinkClick}>{intl.formatMessage({ id: "PRICING" })}</a></li>
                    <br />
                    <li><a href={loginUrl} target="_blank" rel="noreferrer"><button className="member-login-btn-collapse">{intl.formatMessage({ id: "MEMBER_LOGIN" })}</button></a></li>
                    <br />
                    <li><div onClick={handleLinkClick}><LanguageSwitcher isMobile={true}/></div></li>
                </ul>
            </nav>
        </div>
    );
};

const CircleButton = ({ onClick, isOpen }: any) => (
    <button className={`menu-circle-button ${isOpen ? 'close' : ''}`} onClick={onClick}>
        <span className={`bar ${isOpen ? 'close' : ''}`}></span>
        <span className={`bar ${isOpen ? 'close' : ''}`}></span>
        <span className={`bar ${isOpen ? 'close' : ''}`}></span>
    </button>
);

const Header = ({ isSubPage }: any) => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);

    return (
        <div className="header-collapse">
            <CircleButton onClick={toggleModal} isOpen={modalOpen} />
            <FullPageModal isOpen={modalOpen} onClose={toggleModal} isSubPage={isSubPage} />
        </div>
    );
};

export default Header;
