import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { registerUrl } from "../../content/HomeContent";
import appLogo from '../../assets/images/logo_collapsed.svg';
import LanguageSwitcher from '../language-switcher/LanguageSwitcher';
import heroBackground from '../../assets/images/hero-background.webp';
import './Hero.less';

const Hero = ({ refHome }: any) => {
    const intl = useIntl();
    const heroInnerContainerRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (heroInnerContainerRef.current) {
                heroInnerContainerRef.current.classList.add('animate');
            }
        }, 100);

        return () => clearTimeout(timer);
    }, []);
        
    useEffect(() => {
        const backgroundImg = new Image();
        backgroundImg.src = heroBackground;
        backgroundImg.onload = () => {
            setIsLoading(false);
        };
    }, []);

    return (
        <div id="Home" ref={refHome} className={`hero-container ${isLoading ? 'loading' : 'loaded'}`}  >
            <div className="language-switcher-container-hero">
                <LanguageSwitcher refHero={refHome}/>
            </div>
            <div className="opacity-overlay" />
            <div ref={heroInnerContainerRef} className="hero-inner-container">
                <div className="bizmate-logo">
                    <img alt="bizmate-logo-hero" className="bizmate-logo-hero" src={appLogo} />
                </div>
                <div className="left-column">
                    <span className="notification">{intl.formatMessage({ id: "BIZMATE_LAUNCH" })}</span>
                    <h1 className="heading">
                        <span className="quote1">{intl.formatMessage({ id: "BIZMATE_SLOGAN_LINE1" })}</span>
                        <br />
                        <span className="slanted-background">&nbsp;&nbsp;{intl.formatMessage({ id: "BIZMATE_SLOGAN_LINE2" })}&nbsp;&nbsp;</span>
                    </h1>
                    <button className="primary-action"><a className="primary-action-link" href={registerUrl} target="_blank" rel="noreferrer">{intl.formatMessage({ id: "SIGN_UP_FOR_FREE" })}</a></button>
                </div>
                <a href="#ProductOverview">
                    <div className="arrow-container">
                        <div className="arrow-down"></div>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default Hero;
