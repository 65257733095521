import { useRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./WhyChooseUs.less";
import Overview from "../../assets/images/benefits/UI-1.webp";
import Reminder from "../../assets/images/benefits/EmailReminder.webp";
import SSMLogo from "../../assets/images/benefits/SsmLogo.webp";
import AutoReport from "../../assets/images/benefits/AutoReport.webp";
import Anywhere from "../../assets/images/benefits/Benefits-Anytime-Anywhere.webp";
import { Skeleton } from "antd";

const WhyChooseUs = ({ refAboutUs }: any) => {
    const intl = useIntl();
    const carouselRef = useRef<HTMLDivElement>(null);
    const [imageLoading, setImageLoading] = useState({
        Overview: true,
        Reminder: true,
        SSMLogo: true,
        Anywhere: true,
        AutoReport: true,
    });

    useEffect(() => {
        const items = document.querySelectorAll(".why-choose-us-row, .why-choose-us-row-reverse, .why-choose-us-title");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("fade-in");
                    }
                });
            },
            { threshold: 0.1 }
        );

        items.forEach((item) => {
            observer.observe(item);
        });

        return () => {
            items.forEach((item) => {
                observer.unobserve(item);
            });
        };
    }, []);

    // UseEffect to check if all images are loaded
    useEffect(() => {
        const images = [
            Overview,
            Reminder,
            SSMLogo,
            Anywhere,
            AutoReport
        ];

        let loadedCount = 0;
        const checkAllImagesLoaded = () => {
            loadedCount++;
            if (loadedCount === images.length) {
                // All images are loaded
                setImageLoading({
                    Overview: false,
                    Reminder: false,
                    SSMLogo: false,
                    Anywhere: false,
                    AutoReport: false,
                });
            }
        };

        images.forEach((src) => {
            const img = new Image();
            img.onload = checkAllImagesLoaded;
            img.src = src;
        });

        return () => {
            images.forEach((src) => {
                const img = new Image();
                img.onload = null;
            });
        };
    }, []);

    return (
        <div id="Benefits" ref={refAboutUs} className="why-choose-us-container">
            <h1 className="why-choose-us-header">
                <span className="why-choose-us-header-1">{intl.formatMessage({ id: "FEATURES_HEADING_LINE01" })}</span>
                <span className="why-choose-us-header-2">{intl.formatMessage({ id: "FEATURES_HEADING_LINE2" })}</span>
                <span className="why-choose-us-header-2">{intl.formatMessage({ id: "FEATURES_HEADING_LINE3" })}</span>
            </h1>
            <div className="why-choose-us-content" ref={carouselRef}>
                <div className="why-choose-us-row-reverse">
                    <div className="why-choose-us-column text-column">
                        <div className="why-choose-us-item">
                            <span className="why-choose-us-number">01</span>
                            <h2 className="why-choose-us-heading">{intl.formatMessage({ id: "INCREASE_PRODUCTIVITY_AND_EFFICIENCY" })}</h2>
                            <p className="why-choose-us-description">{intl.formatMessage({ id: "INCREASE_PRODUCTIVITY_AND_EFFICIENCY_DESC" })}</p>
                        </div>
                    </div>
                    <div className="why-choose-us-column image-column">
                        { imageLoading.AutoReport
                            ? <Skeleton.Image active={true} className="why-choose-us-img-skeleton" />
                            : <img src={AutoReport} className="team-illustration" alt="Team Illustration" />
                        }
                    </div>
                </div>
                <div className="why-choose-us-row">
                    <div className="why-choose-us-column image-column">
                        { imageLoading.SSMLogo
                            ? <Skeleton.Image active={true} className="why-choose-us-img-skeleton" />
                            : <img src={SSMLogo} className="team-illustration" alt="Team Illustration" />
                        }
                    </div>
                    <div className="why-choose-us-column text-column">
                        <div className="why-choose-us-item">
                            <span className="why-choose-us-number">02</span>
                            <h2 className="why-choose-us-heading">{intl.formatMessage({ id: "GOVERNMENT_COMPLIANT" })}</h2>
                            <p className="why-choose-us-description">{intl.formatMessage({ id: "GOVERNMENT_COMPLIANT_DESC" })}</p>
                        </div>
                    </div>
                </div>
                <div className="why-choose-us-row-reverse">
                    <div className="why-choose-us-column text-column">
                        <div className="why-choose-us-item">
                            <span className="why-choose-us-number">03</span>
                            <h2 className="why-choose-us-heading">{intl.formatMessage({ id: "SIMPLE_UI" })}</h2>
                            <p className="why-choose-us-description">{intl.formatMessage({ id: "SIMPLE_UI_DESC" })}</p>
                        </div>
                    </div>
                    <div className="why-choose-us-column image-column">
                        { imageLoading.Overview
                            ? <Skeleton.Image active={true} className="why-choose-us-img-skeleton" />
                            : <img src={Overview} className="team-illustration" alt="Team Illustration" />
                        }
                    </div>
                </div>
                <div className="why-choose-us-row">
                    <div className="why-choose-us-column image-column">
                        { imageLoading.Reminder
                            ? <Skeleton.Image active={true} className="why-choose-us-img-skeleton" />
                            : <img src={Reminder} className="team-illustration" alt="Team Illustration" />
                        }
                    </div>
                    <div className="why-choose-us-column text-column">
                        <div className="why-choose-us-item">
                            <span className="why-choose-us-number">04</span>
                            <h2 className="why-choose-us-heading">{intl.formatMessage({ id: "ONE_TIME_SETUP" })}</h2>
                            <p className="why-choose-us-description">{intl.formatMessage({ id: "ONE_TIME_SETUP_DESC" })}</p>
                        </div>
                    </div>
                </div>
                <div className="why-choose-us-row-reverse">
                    <div className="why-choose-us-column text-column">
                        <div className="why-choose-us-item">
                            <span className="why-choose-us-number">05</span>
                            <h2 className="why-choose-us-heading">{intl.formatMessage({ id: "STREAMLINE_ANYWHERE" })}</h2>
                            <p className="why-choose-us-description">{intl.formatMessage({ id: "STREAMLINE_ANYWHERE_DESC" })}</p>
                        </div>
                    </div>
                    <div className="why-choose-us-column image-column">
                        { imageLoading.Overview
                            ? <Skeleton.Image active={true} className="why-choose-us-img-skeleton" />
                            : <img src={Anywhere} className="team-illustration" alt="Team Illustration" />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyChooseUs;
