import React from 'react';
import { useIntl } from 'react-intl';
import { loginUrl } from "../../content/HomeContent";
import appLogo from '../../assets/images/logo_collapsed.svg';
import LanguageSwitcher from '../language-switcher/LanguageSwitcher';
import './Header.less';

const Header = ({ isBlack, isSubPage, refHero }: any) => {
  const intl = useIntl();
  return (
    <header className={`transparent-header ${isBlack ? 'blacked' : ''}`}>
      <nav className="nav-container">
        <a className="header-logo-link" href={isSubPage ? "/" : "#Home"}>
          <div className={`logo-container ${isBlack ? 'blacked' : ''}`}>
            <img className="logo-image-small" src={appLogo} alt="Bizmate Logo" />
            <span className={`company-name ${isBlack ? 'blacked' : ''}`}>{intl.formatMessage({ id: "BIZMATE" })}</span>
          </div>
        </a>
        <ul className={`navigation-menu ${isBlack ? 'blacked' : ''}`}>
          <li><a href={isSubPage ? "/" : "#Home"}>{intl.formatMessage({ id: "HOME" })}</a></li>
          <li><a href={isSubPage ? "/" : "#ProductOverview"}>{intl.formatMessage({ id: "OVERVIEW" })}</a></li>
          <li><a href={isSubPage ? "/" : "#Features"}>{intl.formatMessage({ id: "FEATURE" })}</a></li>
          <li><a href={isSubPage ? "/" : "#Benefits"}>{intl.formatMessage({ id: "BENEFITS" })}</a></li>
          <li><a href={isSubPage ? "/" : "#ContactUs"}>{intl.formatMessage({ id: "CONTACT" })}</a></li>
          <li><a href="/faq">{intl.formatMessage({ id: "FAQ" })}</a></li>
          <li><a href="/pricing">{intl.formatMessage({ id: "PRICING" })}</a></li>
        </ul>
        <div className="language-switcher-container">
          <button className="member-login-button"><a href={loginUrl} target="_blank" rel="noreferrer">{intl.formatMessage({ id: "MEMBER_LOGIN" })}</a></button>
          <LanguageSwitcher isBlack={isBlack} refHero={refHero} isSubPage={isSubPage}/>
        </div>
      </nav>
    </header>
  );
};

export default Header;
