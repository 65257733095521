import { IntlShape } from "react-intl";

export const getPricingPlans = (intl: IntlShape) => [
    {
        name: intl.formatMessage({ id: "STARTER_TITLE" }),
        price: "RM 20",
        credit: intl.formatMessage({ id: "STARTER_CREDITS" }),
        mainFeature: intl.formatMessage({ id: "STARTER_MAIN_FEATURE" }),
        basicFeatureText: intl.formatMessage({ id: "STARTER_BASIC_FEATURE_TEXT" }),
        features: [
            intl.formatMessage({ id: "STARTER_BASIC_FEATURE_1" }),
            intl.formatMessage({ id: "STARTER_BASIC_FEATURE_2" }),
            intl.formatMessage({ id: "STARTER_BASIC_FEATURE_3" }),
            intl.formatMessage({ id: "STARTER_BASIC_FEATURE_4" }),
            intl.formatMessage({ id: "STARTER_BASIC_FEATURE_5" }),
            intl.formatMessage({ id: "STARTER_BASIC_FEATURE_6" }),
            intl.formatMessage({ id: "STARTER_BASIC_FEATURE_7" }),
        ],
        advanceFeatureText: intl.formatMessage({ id: "ADVANCED_FEATURES_TEXT_1" }),
        advancedFeatures: [
            intl.formatMessage({ id: "STARTER_ADVANCE_FEATURE_1" }),
            intl.formatMessage({ id: "STARTER_ADVANCE_FEATURE_2" }),
        ],
        buttonText: intl.formatMessage({ id: "STARTER_BTN_TEXT" }),
        featured: false
    },
    {
        name: intl.formatMessage({ id: "PROFESSIONAL_TITLE" }),
        price: "RM 200",
        credit: intl.formatMessage({ id: "PROFESSIONAL_CREDITS" }),
        mainFeature: intl.formatMessage({ id: "PROFESSIONAL_MAIN_FEATURE" }),
        basicFeatureText: intl.formatMessage({ id: "PROFESSIONAL_BASIC_FEATURE_TEXT" }),
        features: [],
        advanceFeatureText: intl.formatMessage({ id: "ADVANCED_FEATURES_TEXT_2" }),
        advancedFeatures: [
            intl.formatMessage({ id: "PROFESSIONAL_ADVANCE_FEATURE_1" }),
            intl.formatMessage({ id: "PROFESSIONAL_ADVANCE_FEATURE_2" }),
        ],
        buttonText: intl.formatMessage({ id: "PROFESSIONAL_BTN_TEXT" }),
        featured: true
    },
    {
        name: intl.formatMessage({ id: "BUSINESS_TITLE" }),
        price: "RM 1000",
        credit: intl.formatMessage({ id: "BUSINESS_CREDITS" }),
        mainFeature: intl.formatMessage({ id: "BUSINESS_MAIN_FEATURE" }),
        basicFeatureText: intl.formatMessage({ id: "BUSINESS_BASIC_FEATURE_TEXT" }),
        features: [],
        advanceFeatureText: intl.formatMessage({ id: "ADVANCED_FEATURES_TEXT_2" }),
        advancedFeatures: [
            intl.formatMessage({ id: "BUSINESS_ADVANCE_FEATURE_1" }),
            intl.formatMessage({ id: "BUSINESS_ADVANCE_FEATURE_2" }),
        ],
        buttonText: intl.formatMessage({ id: "BUSINESS_BTN_TEXT" }),
        featured: false
    },
];
