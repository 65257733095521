import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import Overview from "../../assets/images/Overview.webp";
import "./ProductOverview.less";

const ProductOverview = ({ refProductOverview }: any) => {
    const intl = useIntl();
    const productOverviewImageColumnRef = useRef<HTMLDivElement>(null);
    const productOverviewTextColumnRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const imageObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        imageObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const textObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                        textObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const currentImageRef = productOverviewImageColumnRef.current;
        const currentTextRef = productOverviewTextColumnRef.current;

        if (currentImageRef) {
            imageObserver.observe(currentImageRef);
        }

        if (currentTextRef) {
            textObserver.observe(currentTextRef);
        }

        return () => {
            if (currentImageRef) {
                imageObserver.unobserve(currentImageRef);
            }
            if (currentTextRef) {
                textObserver.unobserve(currentTextRef);
            }
        };
    }, []);

    return (
        <div id="ProductOverview" ref={refProductOverview} className="product-overview-container">
            <div ref={productOverviewImageColumnRef} className="product-overview-image-column">
                <img src={Overview} alt="Team Illustration" />
            </div>
            <div ref={productOverviewTextColumnRef} className="product-overview-text-column">
                <h2 className="product-overview-heading">{intl.formatMessage({ id: "PRODUCT_OVERVIEW_HEADER" })}</h2>
                <p className="product-overview-description">{intl.formatMessage({ id: "PRODUCT_OVERVIEW_DESC" })}</p>

                {/* <div className="product-overview-btn-content">
                    <a href="#Features" className="product-overview-btn">{intl.formatMessage({ id: "LEARN_MORE" })}</a>
                </div> */}
            </div>
        </div>
    );
};

export default ProductOverview;
