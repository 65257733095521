import { IntlProvider } from 'react-intl';
import { Route, Routes, useLocation } from 'react-router-dom';
import { SET_SCREENSIZE, useLayout } from './hooks/LayoutContext';
import { useEffect } from 'react';

import { analytics, logEvent } from './firebaseConfig';
import Content from './pages/Content';
import Plans from './pages/pricing/Pricing';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Faq from './pages/faq/Faq';

import messages_en from './assets/i18n/en.json';
import message_zh_CN from './assets/i18n/zh_CN.json';
import message_ms from './assets/i18n/ms.json';

import './App.less';

function App() {
  const { layoutState, layoutDispatch } = useLayout();
  const location = useLocation();

  const messages: any = {
    'en': messages_en,
    'zh-CN': message_zh_CN,
    'ms': message_ms
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      const dimension = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      layoutDispatch({ type: SET_SCREENSIZE, payload: dimension });
    });
    
    // Log initial page view
    logEvent(analytics, 'page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  useEffect(() => {
    // Log page view on route change
    logEvent(analytics, 'page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: location.pathname
    });
  }, [location]);

  return (
    <IntlProvider locale={layoutState.locale} messages={layoutState.locale ? messages[layoutState.locale] : ''} key={layoutState.locale}>
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="*" element={<Content />} />
        <Route path="/pricing" element={<Plans />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </IntlProvider>
  );
}

export default App;
