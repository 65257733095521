import React from "react";
import { useIntl } from "react-intl";
// import { ReactComponent as FacebookIcon } from "../../assets/images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "../../assets/images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../assets/images/youtube-icon.svg";
import logo from "../../assets/images/logo_collapsed.svg";
import './Footer.less';

export default function Footer({ isSubPage } :any) {
    const intl = useIntl();
    return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="footer-row">
                    <div className="footer-logo-container">
                        <img src={logo} className="footer-logo-img" alt="Logo" />
                        <h5 className="footer-logo-text">{intl.formatMessage({ id: "BIZMATE" })}</h5>
                    </div>
                    <div className="footer-links-container">
                        <a href={isSubPage ? "/" : "#Home"} className="link">{intl.formatMessage({ id: "HOME" })}</a>
                        <a href={isSubPage ? "/" : "#ProductOverview"} className="link">{intl.formatMessage({ id: "OVERVIEW" })}</a>
                        <a href={isSubPage ? "/" : "#Features"} className="link">{intl.formatMessage({ id: "FEATURE" })}</a>
                        <a href={isSubPage ? "/" : "#Benefits"} className="link">{intl.formatMessage({ id: "BENEFITS" })}</a>
                        <a href={isSubPage ? "/" : "#ContactUs"} className="link">{intl.formatMessage({ id: "CONTACT" })}</a>
                        <a href="/faq" className="link">{intl.formatMessage({ id: "FAQ" })}</a>
                        <a href="/pricing" className="link">{intl.formatMessage({ id: "PRICING" })}</a>
                        <a href="/privacy-policy" className="link">{intl.formatMessage({ id: "PRIVACY_POLICY" })}</a>
                    </div>
                    {/* <div className="social-links-container">
                        <a className="social-link">
                            <FacebookIcon />
                        </a>
                        <a className="social-link">
                            <TwitterIcon />
                        </a>
                        <a className="social-link">
                            <YoutubeIcon />
                        </a>
                    </div> */}
                    <p className="copyright-text">
                        {intl.formatMessage({ id: "COPYRIGHT" }, {currentYear: new Date().getFullYear()})}
                    </p>
                </div>
            </div>
        </div>
    );
}
