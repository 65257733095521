import React, { ReactNode } from 'react';
import { IntlShape } from 'react-intl';
import { HashLink as Link } from 'react-router-hash-link';

type FAQItem = {
  question: string;
  answer: ReactNode;
};

export const getFaqContent = (intl: IntlShape): FAQItem[] => [
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_1' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_1' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_2' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_2' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_3' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_3' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_4' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_4' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_5' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_5' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_6' }),
    answer: React.createElement(
        React.Fragment,
        null,
        React.createElement(
          Link,
          { to: '/privacy-policy' },
          intl.formatMessage({ id: 'FAQ_ANSWER_6_ANCHOR' })
        ),
        intl.formatMessage({ id: 'FAQ_ANSWER_6' })
      ),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_7' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_7' }),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_8' }),
    answer: React.createElement(
        React.Fragment,
        null,
        intl.formatMessage({ id: 'FAQ_ANSWER_8_1' }),
        React.createElement(
          Link,
          { to: '/#Benefits' },
          intl.formatMessage({ id: 'FAQ_ANSWER_8_ANCHOR' })
        ),
        intl.formatMessage({ id: 'FAQ_ANSWER_8_2' })
      ),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_9' }),
    answer: React.createElement(
        React.Fragment,
        null,
        intl.formatMessage({ id: 'FAQ_ANSWER_9_1' }),
        React.createElement(
          Link,
          { to: 'https://member.bizmate2u.com/auth/register' },
          intl.formatMessage({ id: 'FAQ_ANSWER_9_ANCHOR_1' })
        ),
        intl.formatMessage({ id: 'FAQ_ANSWER_9_2' }),
        React.createElement(
            Link,
            { to: '/#ContactUs' },
            intl.formatMessage({ id: 'FAQ_ANSWER_9_ANCHOR_2' })
          ),
        intl.formatMessage({ id: 'FAQ_ANSWER_9_3' }),

      ),
  },
  {
    question: intl.formatMessage({ id: 'FAQ_QUESTION_10' }),
    answer: intl.formatMessage({ id: 'FAQ_ANSWER_10' }),
  },
];
